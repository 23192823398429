<template>
	<div class="body">
		<Form :model="data" :rules="rules" :label-width="120" :label-colon="true" class="form">
			<FormItem label='名称' prop="name">
				<Input v-model="data.name" style="width: 200px;" placeholder="请输入插件名称"></Input>
			</FormItem>
			<FormItem label='简介' prop="simple">
				<Input v-model="data.simple" style="width: 200px;" placeholder="请输入插件简介"></Input>
			</FormItem>
			<FormItem label='价格(元)' prop="money">
				<Input v-model="data.money" type="number" style="width: 200px;" placeholder="请输入插件价格"></Input>
			</FormItem>
			<FormItem label="图标" prop="icon">
				<div class="fi-tips">*建议上传尺寸到达72px*72px</div>
				<img v-if="data.icon" :src="fullImage(data.icon)" alt="" class="fi-icon">
				<Uploads class="fi-upload" :data="{dir:'plugin/temp'}" @upload_succ="uploads0_succ"
					:action="apiUrl+'/adm/upload_object'"></Uploads>
			</FormItem>
			<FormItem label="详情图片" prop="desc">
				<div class="fi-tips">*展示于商家后台插件模板-插件市场-插件详情，不论大小</div>
				<img v-if="data.desc" :src="fullImage(data.desc)" alt="" class="fi-icon">
				<Uploads class="fi-upload" :data="{dir:'plugin/temp'}" @upload_succ="uploads1_succ"
					:action="apiUrl+'/adm/upload_object'"></Uploads>
			</FormItem>
		</Form>
		<div class="submit-bt">
			<Button type="primary" @click="submit">保存</Button>
			<Button style="margin-left: 20px;" @click="$router.back(-1)">取消</Button>
		</div>
	</div>
</template>

<script>
	import Uploads from '../../../components/common/Uploads.vue'
	export default {
		components: {
			Uploads
		},
		data() {
			return {
				apiUrl: this.ApiUrl,
				serveUrl: this.serveUrl,
				id: null,
				data: {},
				rules: {
					name: [{
						required: true,
						type: 'string',
						message: '请输入插件名称',
						trigger: 'blur'
					}],
					icon: [{
						required: true,
						message: '请上传图标',
						trigger: 'change'
					}],
					desc: [{
						required: true,
						message: '请上传插件详情图片',
						trigger: 'change'
					}],
					simple: [{
						required: true,
						message: '请输入插件简介',
						trigger: 'blur'
					}],
					money: [{
						required: true,
						type: 'number',
						message: '请输入所需金额',
						triiger: 'blur'
					}]
				}
			}
		},
		created() {
			this.id = this.$route.query.id
			console.log(this.id)
			this.init()
		},
		methods: {
			init() {
				var _this = this
				this.requestApi('/adm/find_plugin',{id: this.id}).then(
					(res) => {
						_this.data = res.data
					}
				)
			},
			submit() {
				var _this = this
				
				this.requestApi('/adm/update_plugin',{data: this.data}).then(
					(res) => {
						if(res.code == 200) {
							_this.alertSucc(res.msg)
							_this.toUrl('/plugin/list');
						}else{
							_this.$Message.error(res.msg)
						}
					}
				)
				
			},
			uploads0_succ(e) {
				this.data.icon = e.pic_url
			},
			uploads1_succ(e) {
				this.data.desc = e.pic_url
			},
		}
	}
</script>

<style scoped="scoped">
	.body {
		padding: 20px;
		background-color: #fff;
	}

	.fi-upload {
		float: left;
		width: 77px;
		height: 77px;
		box-sizing: border-box;
	}

	.fi-tips {
		font-size: 12px;
		color: #999999;
	}

	.fi-icon {
		display: block;
		float: left;
		margin-right: 12px;
		width: 77px;
		height: 77px;
	}
	
	.submit-bt {
		margin-top: 20px;
		text-align: right;
	}
</style>
